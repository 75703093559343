<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
    
    
   
       <div  v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
        <a   
            
         @click="trocar_pagina('gerenFuncionarios')" class="card card-custom wave wave-animate-fast wave-success">
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('gerenFuncionarios')"
                  class="text-dark text-hover-danger font-weight-bold font-size-h4 mb-3"
                >
                  Funcionarios
                </a>
                <div class="text-dark-75">Aqui pode visualizar Funcionarios</div>
              </div>
            </div>
          </div>
        </a>
      </div>
           <div  v-show="lista_permissoes_filial.ra_Ordem" class="col-xxl-4 mt-5">
        <a   
            
         @click="trocar_pagina('ordem')" class="card card-custom wave wave-animate-fast wave-info">
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('ordem')"
                  class="text-dark text-hover-danger font-weight-bold font-size-h4 mb-3"
                >
                  Ordens
                </a>
                <div class="text-dark-75">Aqui pode visualizar Ordens</div>
              </div>
            </div>
          </div>
        </a>
      </div>

          <div  v-show="lista_permissoes_filial.ra_Cobranca" class="col-xxl-4 mt-5">
        <a   
            
         @click="trocar_pagina('cobranca')" class="card card-custom wave wave-animate-fast wave-primary">
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('ordem')"
                  class="text-dark text-hover-danger font-weight-bold font-size-h4 mb-3"
                >
                  Cobranças
                </a>
                <div class="text-dark-75">Aqui pode visualizar Cobranças</div>
              </div>
            </div>
          </div>
        </a>
      </div>
       
     
     
         
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
   computed:{
  lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  name: "dashboard",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },

 
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota){
      this.$router.push({ name: rota });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
